<template>
  <v-container class="fill-height secondary un-bg" fluid>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card flat class="elevation-3" tile>
          <v-toolbar color="primary" dark flat>
            <v-img max-height="40" max-width="40" src="/logo.png" />
            <v-toolbar-title
              class="text-uppercase font-weight-bold subtitle-2"
              style="padding: 0 3px;"
              >UNDSS SRS</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent>
              <p>
                Please enter your email and we will send you a link to complete
                your password-reset operation.
              </p>
              <v-text-field
                v-model="email"
                :label="$t('user.email')"
                :rules="rules.email"
                name="email"
                prepend-icon="mdi-account-circle"
                type="text"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" tile depressed @click="navigateToLogin">
              {{ $t("login.login") }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              tile
              depressed
              :loading="loading"
              @click="sendPasswordReset"
            >
              {{ $t("general.okay") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <copyright :size="64" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Copyright from "@/common/components/Copyright";

export default {
  name: "ForgotPassword",
  components: { Copyright },
  data() {
    return {
      loading: false,
      redirect: "/",
      email: null,
      rules: {
        email: [
          (v) => !!v || "Email is required",
          (v) => {
            const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
            return !!v && re.test(String(v).toLowerCase());
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("auth", ["passwordReset"]),

    hitEnter(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.tryLogin();
      }
    },
    sendPasswordReset() {
      this.loading = true;
      this.passwordReset(this.email)
        .then(() => {
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    navigateToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
